import { createSelector, createFeatureSelector, Action, combineReducers } from '@ngrx/store';
import * as _ from 'lodash';

import * as fromRoot from 'src/app/reducers';
import * as fromDetail from './layout.reducer';

export const featureKey = 'layout';

export interface LayoutState {
  [fromDetail.featureKey]: fromDetail.State;
}

export interface State extends fromRoot.State {
  [featureKey]: LayoutState;
}

export function reducers(state: LayoutState | undefined, action: Action) {
  return combineReducers({
    [fromDetail.featureKey]: fromDetail.reducer,
  })(state, action);
}

// ------------------------------- selectors ------------------------------------------------------
export const selectModuleState = createFeatureSelector<LayoutState>(featureKey);
export const selectDetailState = createSelector(
  selectModuleState,
  (state: LayoutState) => state[fromDetail.featureKey],
);

// -------------------------------------------------------------------------------------

export const selectOpenMenuYn = createSelector(selectDetailState, fromDetail.getOpenMenuYn);
