import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers';
import * as fromModule from '../../reducers';
import { LayoutActions } from '../../actions';
import { AuthActions } from 'src/app/auth/actions';
import { RouterModule, Routes, Router } from '@angular/router';


@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
})
export class LayoutHeaderComponent implements OnInit {

  logInYn$: Observable<boolean>;

  constructor(
    private readonly authStore: Store<fromAuth.State>,
    private readonly layoutStore: Store<fromModule.State>,
    private router: Router,
  ) {
    this.logInYn$ = this.authStore.pipe(select(fromAuth.selectLoggedIn));
  }
  
  ngOnInit(): void {
  }

  signInHandler() {
    this.layoutStore.dispatch(LayoutActions.openSignIn());
  }

  signOutHandler() {
    this.authStore.dispatch(AuthActions.signOut());
    // this.router.navigate(['login'];
  }

  
  

}