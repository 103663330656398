import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

// services
import { AuthDataService } from '../../services/auth-data.service';

@Component({
  selector: 'app-auth-form',
  templateUrl: './form.component.html',
})

export class AuthFormComponent implements OnInit {

  @Output() submitEvent = new EventEmitter<any>();
  loginForm: FormGroup; // [formGroup] = "component에서 사용할 변수명"
  checkValid: boolean; // 상태 변경을 위한 변수
  // checkEmpty$:Observable<boolean>;

  constructor(private readonly dataService: AuthDataService) {
    this.loginForm = this.dataService.generateSignInForm();
    this.checkValid = false; // 임의    
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.checkValid = true;
      return;
    }
    this.submitEvent.emit(this.loginForm.value);
    this.checkValid = false;
  }

  checkIsInvalid(controlPath: (string | number)[] | string) { // controlPath : email || password
    return this.checkValid && this.loginForm.get(controlPath) && this.loginForm.get(controlPath)?.invalid;
  }
  
}

// export class AuthFormComponent {
//   @Output() submitEvent = new EventEmitter<any>();

//   loginForm: FormGroup; // template에서 작성한 FormGroup명을 변수로 선언한다
//   emailValue?: string;
//   passwordValue?: string;

//   constructor(private fb: FormBuilder) {
//     // FormBuilder를 DI 로 가져온다

//     this.loginForm = this.fb.group({
//       // ngOnInit 또는 constructor에 formGroup을 정의한다
//       email: ['', [Validators.required]], // 사용되는 모든 form의 formControlName을 입력한다
//       password: ['', [Validators.required]], // ''는 초기값이며, 초기값이 있으면 입력, 없을시 비워둔다 // Validation은 배열 형태로 구성하며, 없으면 빈 배열로 둔다
//     });

//     this.loginForm.valueChanges.subscribe((observer) => {
//       // valueChanges는 form값을 subscribe해서 값의 변화를 실시간으로 체크한다
//       console.log(this.loginForm.valid, 'this.loginForm.valid :: 입력 여부 벨리데이션 체크');
//     });
//   }

//   submit(e: any) {
//     // form 입력 이후에 validation 체크 또는 데이터를 가공하는 처리를 하는 함수이다
//     const { email } = this.loginForm.controls;
//     const { password } = this.loginForm.controls;
//     console.log(email.value, 'email.value:: email 입력값');
//     console.log(password.value, 'password.value:: password 입력값');

//     // 1. Validation 로직을 돌린다.

//     // 2. Validation 통과시, submitEvent 를 호출
//     this.submitEvent.emit(this.loginForm.value);
//   }
// }
