<div #content (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)" style='display: flex; justify-content: center; align-items: center; height: 100%;'>
<!--  <ng-container *ngIf="hasFiles(); else emptyFiles">-->
<!--    <div class="import-area">-->
<!--      <ng-container *ngTemplateOutlet="contentTemplate; context: { $implicit: files }"></ng-container>-->
<!--    </div>-->
<!--  </ng-container>-->

<!--  <ng-template #emptyFiles>-->
<!--    <div>-->
<!--      <p class="color-point1">JPG, PDF, PNG 파일을 업로드 해주세요.</p>-->
<!--    </div>-->
<!--  </ng-template>-->
  <ng-container *ngTemplateOutlet="contentTemplate; context: { $implicit: files }"></ng-container>
</div>
