import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  NgZone,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnInit,
  SkipSelf,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { partition, Subject } from 'rxjs';
import { catchError, debounceTime, share, switchMap, take, takeUntil, map, filter } from 'rxjs/operators';
import { isNil } from 'lodash';
// services
import { AutocompleteApiService } from './autocomplete.api.service';
// components
import { NzAutocompleteComponent, NzAutocompleteOptionComponent } from 'ng-zorro-antd/auto-complete';

type TOption = { label: string; value: string | number | boolean | null };

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class AutocompleteComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() controlName: string;
  @Input() requestUrl: string;
  @Input() dataKey: string;
  @Input() displayKey: string;
  @Input() rqBuild: (q: string) => {};
  @Input() invalidYn: boolean;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  private _options: any[];
  @Input()
  get options() {
    return this._options;
  }
  set options(val: any[]) {
    this._options = val;
    this.optionList = this.convertToOptionList(val);
    this.selectedOption = this.optionList?.length > 0 ? { ...this.optionList[0] } : null;
  }

  @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;
  @ViewChild('auto') nzAutocomplete: NzAutocompleteComponent;

  private autoKeyword$: Subject<string> = new Subject();
  private destroy$ = new Subject<void>();

  optionList: TOption[];
  selectedOption: TOption = null;

  // region lifecycle
  constructor(
    private el: ElementRef,
    public zone: NgZone,
    private cdr: ChangeDetectorRef,
    private apiService: AutocompleteApiService,
  ) {}
  ngOnInit() {
    this.initSubscription();
  }
  ngAfterViewInit() {
    if (this.nzAutocomplete) {
      this.zone.runOutsideAngular(() => {
        this.nzAutocomplete.animationStateChange
          .pipe(
            takeUntil(this.destroy$),
            filter((event) => event.toState === 'void'),
          )
          .subscribe(() => {
            const { label, value } = this.selectedOption || {};
            const inputText = this.inputRef.nativeElement.value;
            // ? option 이 선택되지 않은 상황에서 auto-complete 레이어가 닫힐 경우
            // ? 인풋에 입력된 항목이랑 선택된 항목이 일치한지 여부를 체크한다.
            // ? onOptionSelect() 함수가 아래 로직보다 먼저 호출된다.
            if (inputText?.length > 0 && inputText !== label) {
              if (isNil(value)) {
                this.inputRef.nativeElement.value = '';
              } else {
                this.inputRef.nativeElement.value = label;
              }
            }
          });
      });
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  // endregion

  // region Event
  /**
   * 검색어 입력시 호출
   * @param event
   */
  onInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    let value: number | string | null = target.value;
    this.autoKeyword$.next(value);
  }
  /**
   * 자동완성 옵션이 선택될 경우 호출
   * @param option
   */
  onOptionSelect(option: NzAutocompleteOptionComponent) {
    const { nzValue, nzLabel } = option;
    this.selectedOption = { label: nzLabel, value: nzValue };
    this.onSelect.emit(option);
  }
  // endregion

  /**
   * 구독초기화
   * 1. 자동완성 입력을 구독
   * @private
   */
  private initSubscription() {
    const keyup$ = this.autoKeyword$.pipe(debounceTime(300), takeUntil(this.destroy$), share());
    let [search$, reset$] = partition(keyup$, (query: string) => query && query.trim().length >= 2);
    // 입력값이 있을 경우 -> API를 호출한다
    search$
      .pipe(
        map((query) => {
          return this.rqBuild(query);
        }),
        switchMap((condition) =>
          this.apiService.searchOptionList(this.requestUrl, condition).pipe(
            take(1),
            catchError(() => {
              return [];
            }),
          ),
        ),
      )
      .subscribe((searchResult: any[]) => {
        this.optionList = this.convertToOptionList(searchResult);
        this.cdr.markForCheck();
      });
    // 입력값이 없을경우 -> null 처리
    reset$.subscribe(() => {
      this.optionList = null;
      this.cdr.markForCheck();
    });
  }

  /**
   * API 검색결과 혹은 Input() 데이터를 옵션리스트로 변환
   * @param list
   * @private
   */
  private convertToOptionList(list: any[]): TOption[] | null {
    if (list?.length > 0) {
      return list.map((v) => ({
        ...v,
        label: v[this.displayKey],
        value: v[this.dataKey],
      }));
    }
    return null;
  }
}
