import { createReducer, on } from '@ngrx/store';
import { LayoutActions } from '../actions';

export const featureKey = 'layoutDetail';

export interface State {
  openMenuYn: boolean;
}

export const initialState: State = {
  openMenuYn: false,
};

export const reducer = createReducer(
  initialState,
  on(LayoutActions.openMenu, (state) => ({ ...state, openMenuYn: true })),
  on(LayoutActions.closeMenu, (state) => ({ ...state, openMenuYn: false })),
  on(LayoutActions.toggleMenu, (state) => ({ ...state, openMenuYn: !state.openMenuYn })),
);

export const getOpenMenuYn = (state: State) => state.openMenuYn;
