import { createAction, props } from '@ngrx/store';

// import { ISignInFormCondition, ISignInResult } from '../models';
interface ISignInFormCondition {
  email?: string;
  password?: string;
}
interface ISignInResult {
  userSeq: number;
  name: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  branchSeq?: number;
}

//#region 로그인 관련
export const signIn = createAction('[Auth] sign in', props<{ condition: any }>());
export const signInSuccess = createAction('[Auth] sign in success', props<{ data: ISignInResult }>());
export const signInFailure = createAction('[Auth] sign in failure', props<{ error: any }>());
export const updateAccessToken = createAction(
  '[Auth] update user access token',
  props<{ accessToken: string }>(),
);
export const updateRefreshToken = createAction(
  '[Auth] update user refresh token',
  props<{ refreshToken: string }>(),
);
// 로그아웃
export const signOut = createAction('[Auth] sign out');
//#endregion

//#region 리다이렉트
export const redirectWithLogin = createAction('[Auth] redirect with login');
//#endregion
