import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// ngrx
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  signInYn$: Observable<boolean>;

  constructor(private readonly authStore: Store<fromAuth.State>, private readonly router: Router) {
    this.signInYn$ = this.authStore.pipe(select(fromAuth.selectLoggedIn));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.signInYn$.pipe(
      map((yn) => {
        if (yn) {
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      }),
    );
  }
}
