import { Component, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef, OnInit } from '@angular/core';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { Observable } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import * as _ from 'lodash';
// ngrx
import { Store, select } from '@ngrx/store';
import { LayoutActions } from '../../actions';
import * as fromAuth from 'src/app/auth/reducers';
import * as fromModule from '../../reducers';
import { selectUrl } from 'src/app/reducers/router-selector';
import * as fromLayout from 'src/app/layout/reducers';


type firstDepthMenu = 'main' | 'blList' | 'users' | 'companies' | 'parentCompanies' | 'regexs';

@Component({
  selector: 'app-layout-menu',
  templateUrl: './menu.component.html',
  animations: [
    trigger('animation', [
      state(
        'opened',
        style({
          left: '200px',
        }),
      ),
      state(
        'closed',
        style({
          left: '0px',
        }),
      ),
      transition('opened <=> closed', [animate('0.5s')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LayoutMenuComponent implements OnInit {
  logInYn$: Observable<boolean>;
  userName$: Observable<string>;
  menuOpenYn$: Observable<boolean>;
  openMenuYn$: Observable<boolean>;

  // 1뎁스 메뉴랑 2뎁스 메뉴를 매칭하는 부분. 메뉴 추가됨에 따라 해당 컬렉션이 늘어난다.
  menuCollection: { [key: string]: firstDepthMenu } = {
    '/user': 'users',
    '/bl-list': 'blList',
    '/company': 'companies',
    '/main': 'main',
    '/parent-company': 'parentCompanies',
    '/bl-reg': 'regexs',
  };

  activatedMenu: firstDepthMenu; // 'users' | 'companies';

  constructor(
    private readonly layoutStore: Store<fromModule.State>,
    private readonly authStore: Store<fromAuth.State>,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.menuOpenYn$ = this.layoutStore.pipe(select(fromModule.selectOpenMenuYn));
  }

  ngOnInit(): void {
    // 라우터 변경을 체크하여 현재 활성화된 1뎁스 메뉴를 확인한다.
    this.layoutStore
      .pipe(select(selectUrl))
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((url) => {
        this.activatedMenu = _.get(this.menuCollection, url) || this.activatedMenu;
        this.cdr.markForCheck();
      });
  }

  onToggleMenu() {
    this.layoutStore.dispatch(LayoutActions.toggleMenu());
  }

  onOpenMenu(menuName: firstDepthMenu) {
    this.activatedMenu = menuName;
  }


}
