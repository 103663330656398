import { InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';

// ngrx
import { MetaReducer, Action, ActionReducerMap, createReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { storageMetaReducer, loggerMetaReducer, logoutMetaReducer } from './meta.reducer';

const rootKey = 'root';

interface RootState {}

const initialState: RootState = {};

export interface State {
  [rootKey]: RootState;
  router: RouterReducerState<any>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const rootReducers = new InjectionToken<ActionReducerMap<State, Action>>('Root reducers token', {
  factory: () => ({
    [rootKey]: createReducer(initialState),
    router: routerReducer,
  }),
});

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [storageMetaReducer, logoutMetaReducer, loggerMetaReducer]
  : [storageMetaReducer, logoutMetaReducer];
