import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

import { AutocompleteComponent } from './autocomplete.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NzIconModule, NzInputModule, NzAutocompleteModule],
  exports: [AutocompleteComponent],
  declarations: [AutocompleteComponent],
})
export class AutocompleteModule {}
