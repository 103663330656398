import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, combineLatest, Subject } from 'rxjs';
import {
  catchError,
  switchMap,
  filter,
  take,
  map,
  withLatestFrom,
  tap,
  finalize,
  takeUntil,
} from 'rxjs/operators';
import * as _ from 'lodash';
// ngrx
import { Store, select } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers';
import { AuthActions } from 'src/app/auth/actions';
// services
import { AuthApiService } from 'src/app/auth/services';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  // Token refresh 관련
  isRefreshing: boolean;
  $isRefreshed: Subject<boolean> = new Subject<boolean>();
  $unsubscribe = new Subject<void>();

  accessToken$: Observable<string>;
  refreshToken$: Observable<string>;
  constructor(private authStore: Store<fromAuth.State>, private authApiService: AuthApiService) {
    this.accessToken$ = this.authStore.pipe(select(fromAuth.selectUserAccessToken));
    this.refreshToken$ = this.authStore.pipe(select(fromAuth.selectUserRefreshToken));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // ! 401에러 처리
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.#handle401Error(request, next);
        }
        return throwError(() => error);
      }),
    );
  }

  #handle401Error = (request: HttpRequest<any>, next: HttpHandler) => {
    if (!this.isRefreshing) {
      // 토큰갱신 API 를 호출하고, 토큰을 갱신한다.
      this.isRefreshing = true;
      this.$isRefreshed.next(false);

      return combineLatest([this.accessToken$, this.refreshToken$]).pipe(
        take(1),
        map(([accessToken, refreshToken]) => ({ accessToken, refreshToken })),
        switchMap((condition) => {
          return this.authApiService.refreshToken(condition).pipe(
            tap({
              error: () => {
                // 로그아웃 처리
                // ! 메인스트림이랑 관련이 없는 사이드 이펙트라서 tap() 에서 해당 로직을 실행한다.
                this.authStore.dispatch(AuthActions.signOut());
              },
            }),
            switchMap(({ accessToken, refreshToken }) => {
              this.isRefreshing = false;
              this.$isRefreshed.next(true);
              this.authStore.dispatch(AuthActions.updateAccessToken({ accessToken }));
              this.authStore.dispatch(AuthActions.updateRefreshToken({ refreshToken }));
              return next.handle(this.#addTokenHeader(request, accessToken));
            }),
            catchError((error) => {
              // * 모든 refresh 관련된 구독을 끊어버린다.
              this.isRefreshing = false;
              this.$isRefreshed.next(false);
              this.$unsubscribe.next();
              this.$unsubscribe.complete();
              if (error instanceof HttpErrorResponse) {
                // Http 에러 일 경우 메세지를 추출한다
                return throwError(() => new Error(error.message));
              } else {
                // 500 에러이고, error 가 string 타입의 에러메세지 일 경우
                return throwError(() => new Error(error));
              }
            }),
          );
        }),
      );
    }

    // accessToken이 갱신 되고 $isRefreshed 값이 true 일 때까지 기다렸다가, 새로운 accessToken으로 next.handler() 실행한다.
    return this.accessToken$.pipe(
      takeUntil(this.$unsubscribe),
      withLatestFrom(this.$isRefreshed),
      filter(([token, isRefreshed]) => isRefreshed === true && token?.length > 0),
      switchMap(([token]) => {
        return next.handle(this.#addTokenHeader(request, token));
      }),
    );
  };

  /**
   * HttpRequest.header 에 토큰추가
   * @param request
   * @param token
   * @returns
   */
  #addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }
}
