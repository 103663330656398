 <a class="btn-control" [class.active]="!(menuOpenYn$ | async)" (click)="onToggleMenu()"></a>

 <div id="lnb" [class.minify]="!(menuOpenYn$ | async)" [@animation]="(menuOpenYn$ | async) ? 'opened' : 'closed'">
  <div class="lnb-menu">

    <ul class="menu-depth2">
      <li>
        <a [routerLink]="['/main']" routerLinkActive="active" class="menu-depth2-link" (click)="onOpenMenu('main')">MAIN</a>
      </li>
      <li>
        <a [routerLink]="['/bl-list']" routerLinkActive="active" class="menu-depth2-link" (click)="onOpenMenu('users')">B/L 서식리스트</a>
      </li>
      <li>
        <a [routerLink]="['/user']" routerLinkActive="active" class="menu-depth2-link" (click)="onOpenMenu('users')">회원관리</a>
      </li>
      <li>
        <a [routerLink]="['/company']" routerLinkActive="active" class="menu-depth2-link" (click)="onOpenMenu('companies')">회사관리</a>
      </li>
      <li>
        <a [routerLink]="['/parent-company']" routerLinkActive="active" class="menu-depth2-link" (click)="onOpenMenu('parentCompanies')">그룹사관리</a>
      </li>
      <li>
        <a [routerLink]="['/bl-reg']" routerLinkActive="active" class="menu-depth2-link" (click)="onOpenMenu('regexs')">정규표현식관리</a>
      </li>
    </ul>


  </div>
</div>
