<div id="header">
  <h1 class="header-logo">
    <a [routerLink]="['/']">
      <img src="../../assets/images/common/common-logo.png" alt="WISE CONVEY" />
    </a>
  </h1>

  <button nz-button nzType="primary" type="submit">
    <a *ngIf="logInYn$ | async" (click)="signOutHandler()">로그아웃</a>
    <a *ngIf="!(logInYn$ | async)" (click)="signInHandler()">로그인</a>
  </button>
</div>
