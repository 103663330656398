import { createSelector, createFeatureSelector, Action, combineReducers } from '@ngrx/store';
import * as _ from 'lodash';

import * as fromRoot from 'src/app/reducers';
import * as fromUser from './auth-user.reducer';

export const featureKey = 'auth';

export interface AuthState {
  [fromUser.featureKey]: fromUser.State;
}

export interface State extends fromRoot.State {
  [featureKey]: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
  return combineReducers({
    [fromUser.featureKey]: fromUser.reducer,
  })(state, action);
}

// * selectors -------------------------------------------------------------------------------------
export const selectModuleState = createFeatureSelector<AuthState>(featureKey);
export const selectDetailState = createSelector(
  selectModuleState,
  (state: AuthState) => state[fromUser.featureKey],
);

// -------------------------------------------------------------------------------------
export const selectUserInfo = createSelector(selectDetailState, fromUser.getUserInfo);
export const selectUserSeq = createSelector(selectUserInfo, (user) => user?.userSeq);
export const selectUserName = createSelector(selectUserInfo, (user) => user?.name);
export const selectUserEmail = createSelector(selectUserInfo, (user) => user?.email);
export const selectBranchSeq = createSelector(selectUserInfo, (user) => user?.branchSeq);
export const selectUserAccessToken = createSelector(selectUserInfo, (user) => user?.accessToken);
export const selectUserRefreshToken = createSelector(selectUserInfo, (user) => user?.refreshToken);
export const selectLoggedIn = createSelector(selectUserInfo, (user) => !_.isEmpty(user));
