import { NgModule } from '@angular/core';

import { CustomTemplateDirective } from './template/template.directives';
import { ConnectFormDirective } from './form/form.directives';
import { TextInputDirective } from './form/text-input.directive';

export const DIRECTIVES = [CustomTemplateDirective, ConnectFormDirective, TextInputDirective];

@NgModule({
  declarations: DIRECTIVES,
  exports: DIRECTIVES,
})
export class DirectivesModule {}
