/**
 * API 호출후 후처리 작업
 * 성공시 액션호출, 실패시 액션호출, 타임아웃시 로그인 리다이렉트 등등
 */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
// ngrx
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/reducers';
import { TypedAction } from '@ngrx/store/src/models';
// modal
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(private readonly store: Store<fromRoot.State>, private modal: NzModalService) {}

  successWithCallback(message: string, fn: () => void) {
    this.modal.success({
      nzTitle: message,
      nzOnOk: fn,
    });
  }

  successAlert(message: string, actions?: TypedAction<any>[]) {
    let options: ModalOptions = {
      nzTitle: message,
    };

    if (actions && actions?.length > 0) {
      options.nzOnOk = () => {
        actions.forEach((action: TypedAction<any>) => {
          this.store.dispatch(action);
        })
      };
    }

    this.modal.success(options);
  }

  failureAlert(error: any, customMessage: string, actions?: TypedAction<any>[]) {
    const message = this.errorHandler(error, customMessage);
    let options: ModalOptions = {
      nzTitle: message,
    };

    if (actions && actions?.length > 0) {
      options.nzOnOk = () => {
        actions.forEach((action: TypedAction<any>) => {
          this.store.dispatch(action);
        })
      };
    }

    this.modal.error(options);
  }

  private errorHandler(error: any, customMsg: string): string {
    if (error instanceof HttpErrorResponse && error.status === 401) {
      // 토큰만료일 경우
      // ! 해당 케이스는 발생하지 않는다. HttpInterceptor 에서 처리한다.
      return customMsg;
    } else {
      // 기타에러 발생시
      const { message } = error;
      return message || customMsg;
    }
  }
}
