<ng-container *ngIf="withoutLayoutYn; else contentTpl">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #contentTpl>
  <div id="wrap">
    <app-layout-menu></app-layout-menu>
    <app-layout-header></app-layout-header>

    <div id="container">
      <div id="contents" [@animation]="(menuOpenYn$ | async) ? 'opened' : 'closed'">
        <router-outlet></router-outlet>
      </div>
    </div>

  </div>
</ng-template>

