import * as _ from 'lodash';
// ngrx
import { ActionReducer, Action, INIT, createAction } from '@ngrx/store';
import { AuthActions } from 'src/app/auth/actions';
// service
import { StorageHandler } from 'src/app/core/utils/storage-handler';

// ? SessionStorage 에 저장할 state 가 있으면 해당 stateKey 를 아래 Array에 추가한다.
// ? 예: const stateKeys = ['auth', 'convert']
const SESSION_STORAGE_KEYS = ['auth'];

// * ================================================================================================
//#region 세션 스토리지 메타리듀서
export function storageMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
  let onInit = true; // after load/refresh…
  return (state: S, action: A): S => {
    // reduce the nextState.
    const nextState = reducer(state, action);
    // init the application state.
    if (onInit) {
      // 처음 로딩했을경우 세션스토리지에 저장된 정보들을 스토어에 등록한다.
      onInit = false;
      const savedState = StorageHandler.GetSavedSessionStorage();
      return _.merge(nextState, savedState);
    }
    // save the next state to the application storage.
    const stateToStorage = _.pick(nextState, SESSION_STORAGE_KEYS);
    StorageHandler.SetItems('sessionStorage', stateToStorage);
    return nextState;
  };
}
//#endregion

// * ================================================================================================
//#region 개발환경에서 액션에 대한 콘솔로깅
export function loggerMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
  return (state: S, action: A): S => {
    const nextState = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', nextState);
    console.groupEnd();

    return nextState;
  };
}
//#endregion

// * ================================================================================================
//#region 로그아웃 메타리듀서
export function logoutMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S>) {
  return (state: S, action: A): S => {
    if (action !== null && action.type === AuthActions.signOut.type) {
      // 로그아웃시, 스토어를 초기화 시킨다.
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}
//#endregion
