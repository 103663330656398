<!-- //TODO :::: input과 button zorro에서 가져오기  -->

<div id="login-wrap">
  <div id="login-container">
    <img class="login-logo" src="../../../../assets/images/common/common-logo.png" />
    <div class="login-admin">관리자 로그인</div>
    <!-- <form class="input-form" [formGroup]="loginForm" (ngSubmit)="submit($event)"> -->
    <form class="input-form" [formGroup]="loginForm">
      <input
        nz-input
        type="text"
        formControlName="email"
        placeholder="아이디"
        tabindex="1"
        [class.valid-err]="checkIsInvalid('email')"
      />
      <p *ngIf="checkValid" class="valid-err">*아이디를 확인해주세요.</p>
      <input
        nz-input
        type="password"
        formControlName="password"
        placeholder="비밀번호"
        tabindex="1"
        [class.valid-err]="checkIsInvalid('password')"
      />
      <p *ngIf="checkValid">*비밀번호를 확인해주세요.</p>
      <button nz-button nzType="primary" type="submit" (click)="onSubmit()">로그인</button>
    </form>
  </div>
</div>

<!--
  Note

  [formGroup] = "component에서 사용할 변수명"
  (ngSubmit) = "submit을 수행할 함수명"
  formControlName = "해당 input의 값을 처리할 변수명"
 -->
