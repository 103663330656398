/**
 * @dynamic is for runtime initializing DomHandler.browser
 *
 * If delete below comment, we can see this error message:
 *  Metadata collected contains an error that will be reported at runtime:
 *  Only initialized variables and constants can be referenced
 *  because the value of this variable is needed by the template compiler.
 */
// @dynamic
export class StorageHandler {
  private static ROOT_KEY = '__app_storage__';

  /**
   * Get
   * 세션 또는 로컬 스토리지에서 데이터 추출
   *
   * @param target
   * @param key 추출할 데이터 키.
   *
   * @returns boolean 성공, 실패
   */
  public static GetItem(target: 'sessionStorage' | 'localStorage', key: string) {
    try {
      const storeDataString = window[target].getItem(this.ROOT_KEY);
      const storeData = storeDataString ? JSON.parse(storeDataString) : null;

      if (storeData && storeData.hasOwnProperty(key)) {
        return storeData[key];
      } else {
        return null;
      }
    } catch (error) {
      return {};
    }
  }

  /**
   * Get
   * 세션 또는 로컬 스토리지에서 데이터 추출
   *
   * @param target
   * @param keys
   */
  public static GetItems(target: 'sessionStorage' | 'localStorage', keys: string[]) {
    try {
      const storeDataString = window[target].getItem(this.ROOT_KEY);
      const storeData = storeDataString ? JSON.parse(storeDataString) : null;

      if (storeData) {
        const result = keys.reduce((prev: any, key: string) => {
          if (storeData.hasOwnProperty(key)) {
            return { ...prev, [key]: storeData[key] };
          } else {
            return { ...prev };
          }
        }, {});

        return result;
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  }

  public static GetSavedSessionStorage(): any {
    return JSON.parse(window.sessionStorage.getItem(this.ROOT_KEY) || '{}');
  }

  /**
   * Set
   * 세션 또는 로컬 스토리지에 데이터 세팅
   *
   * @param target
   * @param data
   *
   * @returns boolean 성공, 실패
   */
  public static SetItems(target: 'sessionStorage' | 'localStorage', data: any): boolean {
    try {
      const storeDataString = window[target].getItem(this.ROOT_KEY);
      const storeData = storeDataString ? JSON.parse(storeDataString) : null;

      const newData = Object.assign({}, storeData, data);
      const stringData: any = JSON.stringify(newData);
      window[target].setItem(this.ROOT_KEY, stringData);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * RemoveItem
   * 세션 또는 로컬 스토리지에 키 아이템 삭제
   *
   * @param target 'session' = sessionStorage, 'local' = localStorage
   * @param keys 삭제할 데이터 키.
   *
   * @returns boolean 성공, 실패
   */
  public static RemoveItems(target: 'sessionStorage' | 'localStorage', keys: string[]): boolean {
    try {
      const storeDataString = window[target].getItem(this.ROOT_KEY);
      const storeData = storeDataString ? JSON.parse(storeDataString) : null;

      for (const key in storeData) {
        if (keys.indexOf(key) > -1) {
          delete storeData[key];
        }
      }

      window[target].setItem(this.ROOT_KEY, JSON.stringify(storeData));

      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Clear
   * 세션 또는 로컬 스토리지 삭제
   *
   * @param target 'session' = sessionStorage, 'local' = localStorage
   * @param key 저장 key
   *
   * @returns boolean 성공, 실패
   */
  public static Clear(target: 'sessionStorage' | 'localStorage') {
    try {
      window[target].clear();
      return true;
    } catch (error) {
      return false;
    }
  }
}
