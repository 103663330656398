import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  AfterViewInit,
  AfterContentInit,
  ContentChildren,
  QueryList,
  ViewChild,
  ElementRef,
  NgZone,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// directives
import { CustomTemplateDirective } from 'src/app/shared/directives/template/template.directives';

@Component({
  selector: 'app-drag-uploader',
  templateUrl: './drag-uploader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DragUploaderComponent implements AfterViewInit, AfterContentInit, OnInit, OnDestroy {
  @Output() onClear: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  @ContentChildren(CustomTemplateDirective) templates: QueryList<any>;
  @ViewChild('content') content: ElementRef;

  public dragHighlight: boolean;
  public contentTemplate: TemplateRef<any>;

  focus: boolean;
  duplicateIEEvent: boolean; // flag to recognize duplicate onchange event for file input
  files: File[] = [];

  constructor(
    private el: ElementRef,
    public sanitizer: DomSanitizer,
    public zone: NgZone,
    public cd: ChangeDetectorRef,
  ) {}

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;
      }
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      if (this.content) this.content.nativeElement.addEventListener('dragover', this.onDragOver.bind(this));
    });
  }

  onFileSelect(event: any) {
    if (event.type !== 'drop' && this.isIE11() && this.duplicateIEEvent) {
      this.duplicateIEEvent = false;
      return;
    }

    this.files = [];

    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    let file = files[0];
    if (this.isImage(file)) {
      // ? 이미지일 경우
      file.objectURL = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      this.files.push(file);
      this.onSelect.emit({ originalEvent: event, files: files, currentFiles: this.files });
    } else if (this.isPdf(file)) {
      // ? PDF일 경우
      const fileReader = new FileReader();
      fileReader.onload = () => {
        file.objectURL = new Uint8Array(fileReader.result as ArrayBuffer);
        this.files.push(file);
        this.onSelect.emit({ originalEvent: event, files: files, currentFiles: this.files });
      };
      fileReader.readAsArrayBuffer(file);
    }
  }

  isIE11() {
    return false;
  }

  isImage(file: File): boolean {
    // image/png
    return /^image\//.test(file.type);
  }
  isPdf(file: File): boolean {
    // application/pdf
    return /^application|pdf\//.test(file.type);
  }

  clear() {
    this.files = [];
    this.onClear.emit();
    this.cd.markForCheck();
  }

  // hasFiles(): boolean {
  //   return this.imageUrl?.length > 0 || (this.files && this.files.length > 0);
  // }

  onDragEnter(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  onDragOver(e: any) {
    this.dragHighlight = true;
    e.stopPropagation();
    e.preventDefault();
  }

  onDragLeave(event: any) {
    this.files = event.currentFiles?.length > 0 ? event.currentFiles : [];
  }

  onDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    let allowDrop = files && files.length === 1;
    if (allowDrop) {
      this.onFileSelect(event);
    }
  }

  ngOnDestroy() {
    if (this.content && this.content.nativeElement) {
      this.content.nativeElement.removeEventListener('dragover', this.onDragOver);
    }
  }
}
