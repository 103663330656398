import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// services
import { AuthGuardService } from 'src/app/core/services/guard/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  //#region 로그인 페이지
  {
    path: 'login',
    loadChildren: () => import('src/app/auth/auth.module').then((m) => m.AuthModule),
    data: { title: '로그인페이지', id: 'loginPage' },
  },
  //#endregion

  //#region 관리자 메뉴들
  {
    path: 'main',
    loadChildren: () => import('src/app/routes/main/main.module').then((m) => m.MainModule),
    canActivate: [AuthGuardService],
    data: { title: '메인페이지', id: 'mainPage' },
  },
  {
    path: 'company',
    loadChildren: () => import('src/app/routes/company/company.module').then((m) => m.CompanyModule),
    canActivate: [AuthGuardService],
    data: { title: '회사관리', id: 'CompanyPage' },
  },
  {
    path: 'user',
    loadChildren: () => import('src/app/routes/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuardService],
    data: { title: '사용자관리', id: 'UserPage' },
  },
  {
    path: 'parent-company',
    loadChildren: () =>
      import('src/app/routes/parent-company/parent-company.module').then((m) => m.ParentCompanyModule),
    canActivate: [AuthGuardService],
    data: { title: '그룹사관리', id: 'ParentCompanyPage' },
  },
  {
    path: 'bl-list',
    loadChildren: () => import('src/app/routes/bl-list/bl-list.module').then((m) => m.BlListModule),
    canActivate: [AuthGuardService],
    data: { title: 'B/L 서식리스트', id: 'BlListPage' },
  },
  {
    path: 'bl-upload/:id',
    loadChildren: () => import('src/app/routes/bl-upload/bl-upload.module').then((m) => m.BlUploadModule),
    canActivate: [AuthGuardService],
    data: { title: 'B/L 서식등록 및 수정', id: 'BlUploadPage' },
  },
  {
    path: 'bl-reg',
    loadChildren: () => import('src/app/routes/bl-reg/bl-reg.module').then((m) => m.BlRegModule),
    canActivate: [AuthGuardService],
    data: { title: 'B/L 정규표현식', id: 'BlRegPage' },
  },
  //#endregion
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
