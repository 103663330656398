import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, tap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
// ngrx
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AuthActions } from '../actions';
import * as fromModule from '../reducers';
// services
import { AuthApiService } from '../services';
import { ErrorService } from 'src/app/core/services/error/error.service';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly authStore: Store<fromModule.State>,
    private readonly apiService: AuthApiService,
    private readonly errorService: ErrorService,
  ) {}

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      // 모든 액션들을 감지 및 관리
      ofType(AuthActions.signIn), // ofType : action의 타입을 비교
      switchMap((parameter) => {
        const condition = parameter.condition; // email | password
        // condition: condition: {email: 'delilah@minilook.co.kr', password: 'wiseconvey1234'}
        return this.apiService.login(condition).pipe(
          switchMap((data) => [AuthActions.signInSuccess({ data }), AuthActions.redirectWithLogin()]),
          // data : { accessToken, branchSeq, email, name, refreshToken, userSeq } 의 정보가 들어있다
          catchError((error) => {
            this.errorService.failureAlert(error, '로그인에 실패했습니다.');
            return [];
          }),
        );
      }),
    ),
  );

  signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signOut),
        tap(() => {
          this.router.navigate(['/login']);
        }),
      ),
    { dispatch: false },
  );

  redirectWithLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.redirectWithLogin),
        tap(() => {
          this.router.navigateByUrl('/');
        }),
      ),
    { dispatch: false },
  );
}
