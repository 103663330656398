import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// ngrx
import { StoreModule } from '@ngrx/store';
import * as fromModule from './reducers';
// containers & components
import { LayoutHeaderComponent, LayoutMenuComponent } from './containers';
// zorro
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    NzButtonModule,
    StoreModule.forFeature(fromModule.featureKey, fromModule.reducers),
  ],
  exports: [LayoutHeaderComponent, LayoutMenuComponent],
  declarations: [LayoutHeaderComponent, LayoutMenuComponent],
})
export class LayoutModule {}
