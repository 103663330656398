import { Component } from '@angular/core';
import { trigger, style, transition, animate, state } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';

// ngrx
import { Store, select } from '@ngrx/store';
import * as fromRoot from 'src/app/reducers';
import { selectCurrentRoute } from './reducers/router-selector';
import * as fromLayout from 'src/app/layout/reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('animation', [
      state(
        'opened',
        style({
          'margin-left': '200px',
        }),
      ),
      state(
        'closed',
        style({
          'margin-left': '0px',
        }),
      ),
      transition('opened <=> closed', [animate('0.5s')]),
    ]),
  ],
})
export class AppComponent {
  menuOpenYn$: Observable<boolean>;
  // 레이아웃 사용여부
  withoutLayoutYn: boolean;
  withoutLayoutPages: string[] = ['loginPage', 'BlUploadPage'];

  constructor(
    private readonly store: Store<fromRoot.State>,
    private titleService: Title,
    private layoutStore: Store<fromLayout.State>,
  ) {
    this.menuOpenYn$ = this.layoutStore.pipe(select(fromLayout.selectOpenMenuYn));

    this.store.select(selectCurrentRoute).subscribe((r) => {
      if (r?.data) {
        this.withoutLayoutYn = this.withoutLayoutPages.indexOf(r.data?.id) > -1;
        this.titleService.setTitle(r.data?.title || 'Wise Convey');
      }
    });
  }
}
