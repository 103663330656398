import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../actions';
import { IUserInfo } from '../auth-types';

export const featureKey = 'authUser';

export interface State {
  userInfo: IUserInfo | null;
}

export const initialState: State = {
  userInfo: null,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.signInSuccess, (state, { data }) => ({
    ...state,
    userInfo: data,
  })),
  on(AuthActions.signOut, () => initialState),
  on(AuthActions.updateAccessToken, (state, { accessToken }) => ({
    ...state,
    userInfo: Object.assign({}, state.userInfo, { accessToken }),
  })),
  on(AuthActions.updateRefreshToken, (state, { refreshToken }) => ({
    ...state,
    userInfo: Object.assign({}, state.userInfo, { refreshToken }),
  })),
);

// 사용자 정보 관련
export const getUserInfo = (state: State) => state.userInfo;
