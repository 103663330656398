import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
// services
import { ApiRequestService } from 'src/app/core/services/api/api-request.service';

const ApiPath = {
  signin: `${environment.apiUrl}/api/users/signin`,
  refreshToken: `${environment.apiUrl}/api/users/tokens`,
};

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private apiService: ApiRequestService) {}

  login(params: any): Observable<any> {

    return this.apiService.get(ApiPath.signin, params).pipe( // url: ApiPath.signin, params: params(token)
      switchMap((res) => {
        if (res.code === '200') {
          // res.data : { accessToken, branchSeq, email, name, refreshToken, userSeq } 의 정보가 들어있다
          return of(res.data);
        } else {
          return throwError(() => res);
        }
      }),
    );
  }

  // logout(condition: any): Observable<boolean> {
  //   const params = _.assign({}, this.api.defaultParams, { condition });
  //   return this.api.post(ApiPath.userLogout, params).pipe(
  //     switchMap((res) => {
  //       // 실패여부에 관계없이 true 리턴
  //       return of(true);
  //     }),
  //   );
  // }

  refreshToken(params: any): Observable<any> {
    return this.apiService.put(ApiPath.refreshToken, params).pipe(
      switchMap((res) => {
        if (res.code === '200') {
          return of(res.data);
        } else {
          return throwError(() => res.message);
        }
      }),
    );
  }
}
