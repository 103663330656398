import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromModule from './reducers';
import { AuthEffects } from './effects';
// zorro
import { NzButtonModule } from 'ng-zorro-antd/button';

import { AuthRoutingModule } from './auth-routing.module';
// containers & components
import { AuthComponent } from './containers';
import { AuthFormComponent } from './components';

const CONTAINERS = [AuthComponent];
const COMPONENTS = [AuthFormComponent];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    StoreModule.forFeature(fromModule.featureKey, fromModule.reducers),
    EffectsModule.forFeature([AuthEffects]),
    NzButtonModule,
  ],
  declarations: [CONTAINERS, COMPONENTS],
  exports: [CONTAINERS],
  providers: [],
})
export class AuthModule {}
