import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'src/app/shared/directives';

import { DragUploaderComponent } from './drag-uploader.component';

@NgModule({
  imports: [CommonModule, DirectivesModule],
  exports: [DragUploaderComponent],
  declarations: [DragUploaderComponent],
})
export class DragUploaderModule {}
