import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: '[textInput]',
})
export class TextInputDirective {
  constructor(private el: ElementRef, private renderer: Renderer2, private control: NgControl) {}

  @Input() format:
    | 'time'
    | 'date'
    | 'year'
    | 'number'
    | 'phone'
    | 'upperCase'
    | 'numberOnly'
    | 'cardNo'
    | 'uppserCaseEnOnly'
    | 'uppserCaseEnNumberOnly'
    | 'currency'
    | 'includeMinusCurrency'
    | 'cardValidPeriod'
    | 'mobileNo'
    | 'ko'
    | 'non_ko'
    | 'number_fix3';

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    // get position
    const pos = this.el.nativeElement.selectionStart;
    const value = (event.target as HTMLInputElement).value;
    if (_.isNil(value) || value === '') {
      return;
    }
    // let e = <KeyboardEvent>event;
    // if (this.OnlyNumber) {
    //   if (
    //     [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
    //     // Allow: Ctrl+A
    //     (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
    //     // Allow: Ctrl+C
    //     (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
    //     // Allow: Ctrl+V
    //     (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
    //     // Allow: Ctrl+X
    //     (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
    //     // Allow: home, end, left, right
    //     (e.keyCode >= 35 && e.keyCode <= 39)
    //   ) {
    //     // let it happen, don't do anything
    //     return;
    //   }
    //   // Ensure that it is a number and stop the keypress
    //   if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
    //     e.preventDefault();
    //   }
    // }
    // 포맷에 맞게 재랜더링을 실시
    this.handleInputChange(value);
    // 커서위치 조정
    this.el.nativeElement.setSelectionRange(pos, pos, 'none');
  }

  @HostListener('focusout') focusOut() {
    if (this.control) {
      this.control.control.setValue(this.el.nativeElement.value);
    }
  }

  handleInputChange(value: string) {
    let result;
    switch (this.format) {
      case 'non_ko':
        result = this.convertToNonKo(value);
        break;
      default:
        break;
    }
    this.renderer.setProperty(this.el.nativeElement, 'value', result);
  }

  /**
   * 한글제외만 입력
   * 영문자는 대문자로 변환
   */
  private convertToNonKo(value: string) {
    // 한글 제거
    const result = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '');
    // 대문자 전환
    return _.toUpper(result);
  }
}
