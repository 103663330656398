import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
registerLocaleData(en);

// ngrx
import { StoreModule } from '@ngrx/store';
import { rootReducers, metaReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
// zorro
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
// custom modules
// ? Auth, Layout 스토어가 글로벌에서 쓰이기 때문에 App 모듈에 등록해 줘야 한다.
import { AuthModule } from 'src/app/auth/auth.module';
import { LayoutModule } from 'src/app/layout/layout.module';
import { ScrollToModule } from 'src/app/shared/modules';

// services
import { httpInterceptorProviders } from './core/http-interceptors';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(rootReducers, {
      metaReducers,
      runtimeChecks: {
        // strictStateImmutability and strictActionImmutability are enabled by default
        strictStateSerializability: true,
        // strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    NzModalModule,
    AuthModule,
    LayoutModule,
    ScrollToModule.forRoot(),
  ],
  providers: [
    {
      // url에 # 사용하지 않은 방식으로 설정
      // HashLocationStrategy: # 사용
      // PathLocationStrategy: # 사용안함
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      // Angular 양방향 바인딩에서 한글이 짤리는 문제
      provide: COMPOSITION_BUFFER_MODE,
      useValue: false,
    },
    // ng-zorro-antd 다국어 설정
    { provide: NZ_I18N, useValue: en_US },
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
