<nz-input-group nzSize="default" [nzSuffix]="suffixIcon" [nzStatus]="invalidYn ? 'error' : null">
  <input
    #input
    placeholder="input here"
    nz-input
    [formControlName]="controlName"
    [nzAutocomplete]="auto"
    (input)="onInput($event)"
  />
</nz-input-group>

<ng-template #suffixIcon>
  <i nz-icon nzType="search"></i>
</ng-template>

<nz-autocomplete #auto (selectionChange)="onOptionSelect($event)">
  <!-- 검색결과가 존재할때 -->
  <ng-container *ngIf='optionList?.length > 0'>
    <nz-auto-option *ngFor="let option of optionList" [nzValue]="option.value" [nzLabel]='option.label'>
      {{ option.label }}
    </nz-auto-option>
  </ng-container>
  <!-- 검색결과가 없을 경우 -->
  <ng-container *ngIf='optionList?.length === 0'>
    <nz-auto-option [nzDisabled]='true'>
      검색결과가 존재하지 않습니다.
    </nz-auto-option>
  </ng-container>
</nz-autocomplete>
