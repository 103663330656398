import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
// ngrx
import { Store, select } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  accessToken$: Observable<string>;
  constructor(private readonly authStore: Store<fromAuth.State>) {
    this.accessToken$ = this.authStore.pipe(select(fromAuth.selectUserAccessToken));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.accessToken$.pipe(
      // 현재 accessToken 값을 가져오기 위함으로 한번만 관찰하면 된다.
      take(1),
      switchMap((token) => {
        if (token?.length > 0) {
          // 토큰이 있을 경우 -> request에 header 세팅
          const req = this.#addTokenHeader(request, token);
          return next.handle(req);
        } else {
          // 토큰이 없을 경우 -> request를 그대로 보냄
          return next.handle(request);
        }
      }),
    );
  }
  /**
   * HttpRequest.header 에 토큰추가
   * @param request
   * @param token
   * @returns
   */
  #addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }
}
