import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
// ngrx
import { Store, select } from '@ngrx/store';
import { AuthActions } from '../actions';
import * as fromAuth from '../reducers';
// models
// import { ISignInFormCondition } from '../models';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
})
export class AuthComponent {

  constructor(private readonly authStore: Store<fromAuth.State>) {}

  // signInHandler(condition: ISignInFormCondition) {
  //   this.authStore.dispatch(AuthActions.signIn({ condition }));
  // }

  submitHandler(loginInfo: any) {
    const parameter = { condition: loginInfo };
    this.authStore.dispatch(AuthActions.signIn(parameter));
  }
}
