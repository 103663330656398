import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
// services
import { ApiRequestService } from 'src/app/core/services/api/api-request.service';

@Injectable({ providedIn: 'root' })
export class AutocompleteApiService {
  constructor(private apiService: ApiRequestService) {}

  searchOptionList(url: string, params: any): Observable<any> {
    return this.apiService.get(url, params).pipe(
      switchMap((res) => {
        if (res.code === '200') {
          return of(res.data);
        } else if (res.code === '512') {
          return of([]);
        } else {
          return throwError(() => res);
        }
      }),
    );
  }
}
