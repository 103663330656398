// 폼 빌더를 사용한 폼그룹 만들기

import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })

export class AuthDataService {

  constructor(private fb: FormBuilder) {} // FormBuilder DI

  generateSignInForm = (): FormGroup =>  // form 구성/생성
    this.fb.group({
      email: [null, [Validators.required]], // null === FormControl의 초기값
      password: [null, [Validators.required]],
    });

}


